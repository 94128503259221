import React from "react";
import Seo from "./seo";
import Nav from "./nav";
import Footer from "./footer";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function Layout({ children, seo, alternateHref }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        [theme.breakpoints.down("xl")]: {
          maxWidth: "100vw",
        },
      }}
    >
      <Seo seo={seo} />
      <Nav alternateHref={alternateHref} />
      <main>{children}</main>
      <Footer />
    </Box>
  );
}
