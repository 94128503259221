import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import React, { useCallback } from "react";
import Logo from "../assets/logo/logo-footer.png";
import { isLoggedIn } from "../services/auth";
import { Link as LinkGatsby } from "gatsby";

// based on https://mui.com/components/drawers/#responsive-drawer
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const MobileNav = ({ t, theme, links, menuLinks, alternateHref }) => {
  const [open, setOpen] = React.useState(false);
  const { originalPath, language } = useI18next();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const i18nRedirectionPath =
    alternateHref === false ? "/" : alternateHref || originalPath;

  const onClick = useCallback((linkPath) => {
    window.dataLayer?.push({
      event: "click_exit_link",
      click_text: linkPath,
      click_url: linkPath,
    });
  }, []);

  return (
    <Box sx={{ display: "flex" }} overflow="hidden">
      <CssBaseline />
      <AppBar position="static" color="transparent" open={open}>
        <Toolbar style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }), px: 0 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{ flexGrow: 1 }}
            align="center"
            color="grey.servier_1"
            style={{
              fontSize: "1rem",
              fontWeight: "900",
              letterSpacing: "0.1em",
              textDecoration: "none",
            }}
          >
            SERVIER UNIVERSITY
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Toolbar sx={{ gap: 2 }}>
              <img width="37" height="32" src={Logo} alt="Logo" />
              <Typography
                color="servier.mainBlue"
                style={{
                  fontSize: "14px",
                  fontWeight: 900,
                  letterSpacing: "0.1em",
                }}
                sx={{ flexGrow: 1 }}
              >
                SERVIER UNIVERSITY
              </Typography>
            </Toolbar>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon style={{ color: theme.palette.grey.servier_1 }} />
            </IconButton>
          </Toolbar>
        </DrawerHeader>
        <Divider />

        {isLoggedIn() ? (
          <Link
            key={`/profile`}
            to={`/profile`}
            style={{
              color: theme.palette.grey.servier_1,
              fontSize: 14,
              textDecoration: "none",
            }}
            activeStyle={{ color: theme.palette.servier.lightBlue }}
            onClick={onClick.bind(null, `/profile`)}
          >
            <Box sx={{ px: 2, pt: 2 }}>
              <Typography
                variant="textButton"
                sx={{ textTransform: "uppercase", fontWeight: 900 }}
                style={{ letterSpacing: "0.1em" }}
              >
                {t("nav.profile")}
              </Typography>
            </Box>
          </Link>
        ) : (
          <Link
            key={`/login`}
            to={`/login`}
            style={{
              color: theme.palette.primary.main,
              fontSize: 14,
              textDecoration: "none",
            }}
            activeStyle={{ color: theme.palette.servier.lightBlue }}
            onClick={onClick.bind(null, `/login`)}
          >
            <Box sx={{ px: 2, pt: 2 }}>
              <Typography
                variant="textButton"
                sx={{ textTransform: "uppercase", fontWeight: 900 }}
                style={{ letterSpacing: "0.1em" }}
              >
                {t("nav.loginOrRegister")}
              </Typography>
            </Box>
          </Link>
        )}
        {links.map((link, i) => (
          <Link
            key={link.path}
            to={link.path}
            style={{
              color: theme.palette.grey.servier_1,
              fontSize: 14,
              textDecoration: "none",
            }}
            activeStyle={{ color: theme.palette.servier.lightBlue }}
            onClick={onClick.bind(null, link.path)}
          >
            <Box sx={{ px: 2, pt: 2 }}>
              <Typography
                variant="textButton"
                sx={{ textTransform: "uppercase", fontWeight: 900 }}
                style={{ letterSpacing: "0.1em" }}
              >
                {t(`nav.${link.content}`)}
              </Typography>
            </Box>
          </Link>
        ))}
        <Link
          key="/about-us/"
          to="/about-us/"
          style={{
            color: theme.palette.grey.servier_1,
            fontSize: 13,
            textDecoration: "none",
          }}
          activeStyle={{ color: theme.palette.servier.lightBlue }}
          onClick={onClick.bind(null, "/about-us/")}
        >
          <Box sx={{ px: 2, pt: 2 }}>
            <Typography
              variant="textButton"
              sx={{ textTransform: "uppercase", fontWeight: 900 }}
              style={{ letterSpacing: "0.1em" }}
            >
              <Trans>nav.aboutUs</Trans>
            </Typography>
          </Box>
        </Link>
        <Link
          key={"/servier-university"}
          to={"/servier-university"}
          style={{
            color: theme.palette.grey.servier_1,
            fontSize: 13,
            textDecoration: "none",
          }}
          activeStyle={{ color: theme.palette.servier.lightBlue }}
          onClick={onClick.bind(null, "/servier-university")}
        >
          <Box sx={{ px: 2, pt: 2 }}>
            <Typography
              variant="textButton"
              sx={{ marginLeft: "12px", fontWeight: 900 }}
              style={{ letterSpacing: "0.1em" }}
            >
              <Trans>nav.servierUniversity</Trans>
            </Typography>
          </Box>
        </Link>
        {menuLinks.map((link, i) => (
          <LinkGatsby
            key={link.path}
            to={link.path}
            target="_blank"
            style={{
              color: theme.palette.grey.servier_1,
              fontSize: 13,
              textDecoration: "none",
            }}
            activeStyle={{ color: theme.palette.servier.lightBlue }}
            onClick={onClick.bind(null, link.path)}
          >
            <Box sx={{ px: 2, pt: 2 }}>
              <Typography
                variant="textButton"
                sx={{ marginLeft: "12px", fontWeight: 900 }}
                style={{ letterSpacing: "0.1em" }}
              >
                <Trans>nav.{link.content}</Trans>
              </Typography>
            </Box>
          </LinkGatsby>
        ))}
        <Link
          key={"/products"}
          to={"/products"}
          style={{
            color: theme.palette.grey.servier_1,
            fontSize: 13,
            textDecoration: "none",
          }}
          activeStyle={{ color: theme.palette.servier.lightBlue }}
          onClick={onClick.bind(null, "/products")}
        >
          <Box sx={{ px: 2, pt: 2 }}>
            <Typography
              variant="textButton"
              sx={{ marginLeft: "12px", fontWeight: 900 }}
              style={{ letterSpacing: "0.1em" }}
            >
              <Trans>nav.products</Trans>
            </Typography>
          </Box>
        </Link>
        <Box sx={{ px: 2, pt: 2 }}>
          <Link
            to={i18nRedirectionPath}
            language={language === "en" ? "es" : "en"}
            style={{ textDecoration: "none" }}
          >
            <Typography
              style={{ color: theme.palette.grey.servier_1 }}
              variant="textButton"
              sx={{ textTransform: "uppercase", fontWeight: 900 }}
            >
              <Trans>switchTo</Trans>{" "}
              <Trans>{language === "en" ? "es" : "en"}</Trans>
            </Typography>
          </Link>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileNav;
