import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useCallback } from "react";
import LogoOld from "../assets/logo/logo-footer-old.png";
import Logo from "../assets/logo/logo-footer.png";

const Footer = () => {
  const theme = useTheme();
  const { t } = useI18next();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <footer>
      <Box
        sx={{
          bgcolor: theme.palette.grey.servier_3,
          color: "white",
          display: "flex",
          alignItems: "center",
          py: { lg: 3, xs: 1 },
        }}
      >
        <Container
          maxWidth="xl"
          minwidth="415px"
          sx={{
            textAlign: "justify",
          }}
        >
          <Grid container>
            <Grid item lg={4} xs={12} sx={{ px: { lg: 3, xs: 0 }, py: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  position: "center",
                }}
              >
                <img
                  src={LogoOld}
                  alt="Logo"
                  style={{
                    width: isMobile ? "153px" : "200px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={8} xs={12} sx={{ px: { lg: 0, xs: 1 }, py: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="footerStrong" align="justify">
                  {t("footer.title")}
                </Typography>
                <Typography variant="footerSmall" align="justify">
                  {t("footer.address")}
                </Typography>
                <Typography variant="footerSmall" align="justify">
                  {t("footer.tel")}
                </Typography>
                <Typography variant="footerSmallStrong" align="justify">
                  {t("footer.content")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          bgcolor: theme.palette.grey.servier_4,
          color: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          py: { xl: 2, xs: 1 },
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            sx={{
              alignItems: "center",
            }}
          >
            <Grid
              item
              xl={6}
              xs={12}
              sx={{
                px: { lg: 1, xs: 2 },
                py: 2,
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  px: 0,
                  py: 0,
                  gap: 1,
                }}
              >
                <img src={Logo} alt="Logo" />
                <Typography variant="footerSmall">
                  {t("footer.copyright")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} xs={12} sx={{ px: 0 }}>
              <FooterLinks isMobile={isMobile}></FooterLinks>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};

const FooterLinks = ({ isMobile }) => {
  const { t, language } = useI18next();

  const onClick = useCallback((url, text) => {
    window.dataLayer?.push({
      event: "click_exit_link",
      click_text: text,
      click_url: url,
    });
  }, []);

  return (
    <Container>
      <Grid
        container
        justifyContent={isMobile ? null : "center"}
        sx={{
          px: 0,
          gap: { lg: 2, xs: 0 },
        }}
      >
        <Grid item xs={isMobile ? 5 : null} sx={{ px: 1, py: 2 }}>
          <Link
            to={`/${language === "en" ? "en" : "es"}/terms-of-use`}
            style={{ color: "white", textDecoration: "none" }}
            onClick={onClick.bind(null, "/terms-of-use", "termsOfUse")}
          >
            <Typography variant="footerSmall">
              {t("footer.termsOfUse")}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={isMobile ? 7 : null} sx={{ px: 1, py: 2 }}>
          <Link
            to={`/${language === "en" ? "en" : "es"}/privacy-policy`}
            style={{ color: "white", textDecoration: "none" }}
            onClick={onClick.bind(null, "/privacy-policy", "privacyPolicy")}
          >
            <Typography variant="footerSmall">
              {t("footer.privacyPolicy")}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={isMobile ? 5 : null} sx={{ px: 1, py: 2 }}>
          <Link
            to={`/${language === "en" ? "en" : "es"}/cookie-policy`}
            style={{ color: "white", textDecoration: "none" }}
            onClick={onClick.bind(null, "/cookie-policy", "cookiePolicy")}
          >
            <Typography variant="footerSmall">
              {t("footer.cookiePolicy")}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={isMobile ? 7 : null} sx={{ px: 1, py: 2 }}>
          <Link
            target="_blank"
            to={
              language === "en"
                ? "https://servier.com.pa/en/report-a-side-effect"
                : "https://servier.com.pa/content-evento-adverso"
            }
            style={{ color: "white", textDecoration: "none" }}
            onClick={onClick.bind(
              null,
              "/report-side-effect",
              "reportSideEffect"
            )}
          >
            <Typography variant="footerSmall" color="servier.blue1">
              {t("footer.reportSideEffect")}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
