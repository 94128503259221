import {
  AppBar,
  Button,
  Container,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import { isLoggedIn } from "../services/auth";
import React, { useCallback, useState } from "react";
import LogoHeader from "../assets/logo/logo-header.png";
import LanguageSwitcher from "./language-switcher";
import MobileNav from "./mobile-nav";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { navigate } from "gatsby";
import { Link as LinkGatsby } from "gatsby";

const links = [
  {
    content: "home",
    path: "/",
  },
  {
    content: "courses",
    path: "/courses/",
  },
  {
    content: "medicalInformation",
    path: "/medical-informations/",
  },
  {
    content: "events",
    path: "/live-events/",
  },
  {
    content: "news",
    path: "/news/",
  },
];
const menuLinks = [
  {
    content: "servierMedArt",
    path: "https://smart.servier.com/",
  },
  {
    content: "servierATuLado",
    path: "https://www.servieratulado.com/",
  },
];
const Nav = (props) => {
  const theme = useTheme();
  const { t, language } = useI18next();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const onClick = useCallback((linkPath) => {
    window.dataLayer?.push({
      event: "click_exit_link",
      click_text: linkPath,
      click_url: linkPath,
    });
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLoginButton = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/login/`, {
      state: { prevPath: "/" },
    });
  };
  const onClickRegisterButton = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/register/`);
  };
  if (isMobile) {
    return (
      <MobileNav
        t={t}
        theme={theme}
        links={links}
        menuLinks={menuLinks}
        alternateHref={props.alternateHref}
      />
    );
  }

  return (
    <AppBar
      position="static"
      color="transparent"
      style={{ boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.12)" }}
      maxWidth={false}
    >
      <Container
        maxWidth="xl"
        sx={{
          height: "90px",
          display: "flex",
          alignItems: "space-between",
          mx: { xl: "0 3em 0 3em", lg: "0 3em 0 3em", md: "0 2em 0 2em" },
          padding: { lg: "15px !important" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Link to="/">
              <img src={LogoHeader} alt="Logo" height="62" />
            </Link>
            {links.map((link, i) => (
              <Link
                key={link.path}
                to={link.path}
                style={{
                  color: theme.palette.grey.servier_1,
                  fontSize: 13,
                  textDecoration: "none",
                }}
                activeStyle={{ color: theme.palette.servier.lightBlue }}
                onClick={onClick.bind(null, link.path)}
              >
                <Typography
                  variant="textButton"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 900,
                    letterSpacing: "0.1em",
                    marginRight: 2,
                  }}
                >
                  <Trans>nav.{link.content}</Trans>
                </Typography>
              </Link>
            ))}
            <Link
              key="/about-us/"
              to="/about-us/"
              style={{
                color: theme.palette.grey.servier_1,
                fontSize: 13,
                textDecoration: "none",
              }}
              activeStyle={{ color: theme.palette.servier.lightBlue }}
              onClick={onClick.bind(null, "/about-us/")}
              onMouseEnter={handleHover}
            >
              <Typography
                variant="textButton"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 900,
                  letterSpacing: "0.1em",
                }}
              >
                <Trans>nav.aboutUs</Trans>
              </Typography>
            </Link>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link
                key={"/about-us"}
                to={"/about-us"}
                target="_blank"
                style={{
                  color: theme.palette.grey.servier_1,
                  fontSize: 13,
                  textDecoration: "none",
                }}
                activeStyle={{ color: theme.palette.servier.lightBlue }}
                onClick={onClick.bind(null, "/about-us")}
              >
                <MenuItem onClick={handleClose}>
                  <Trans>nav.aboutUsSmall</Trans>
                </MenuItem>
              </Link>
              <Link
                key={"/servier-university"}
                to={"/servier-university"}
                target="_blank"
                style={{
                  color: theme.palette.grey.servier_1,
                  fontSize: 13,
                  textDecoration: "none",
                }}
                activeStyle={{ color: theme.palette.servier.lightBlue }}
                onClick={onClick.bind(null, "/servier-university")}
              >
                <MenuItem onClick={handleClose}>
                  <Trans>nav.{"servierUniversity"}</Trans>
                </MenuItem>
              </Link>
              {menuLinks.map((link, i) => (
                <LinkGatsby
                  key={link.path}
                  to={link.path}
                  target="_blank"
                  style={{
                    color: theme.palette.grey.servier_1,
                    fontSize: 13,
                    textDecoration: "none",
                  }}
                  activeStyle={{ color: theme.palette.servier.lightBlue }}
                  onClick={onClick.bind(null, link.path)}
                >
                  <MenuItem onClick={handleClose}>
                    <Trans>nav.{link.content}</Trans>
                  </MenuItem>
                </LinkGatsby>
              ))}
              <Link
                key={"/products"}
                to={"/products"}
                target="_blank"
                style={{
                  color: theme.palette.grey.servier_1,
                  fontSize: 13,
                  textDecoration: "none",
                }}
                activeStyle={{ color: theme.palette.servier.lightBlue }}
                onClick={onClick.bind(null, "/products")}
              >
                <MenuItem onClick={handleClose}>
                  <Trans>nav.{"products"}</Trans>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <LanguageSwitcher alternateHref={props.alternateHref} />
            {isLoggedIn() ? (
              <Link
                to={`/profile`}
                language={props.language}
                style={{ textDecoration: "none" }}
              >
                <AccountCircleOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
              </Link>
            ) : (
              <div>
                <Button onClick={onClickLoginButton}>{t("nav.login")}</Button>
                <Button
                  onClick={onClickRegisterButton}
                  variant="contained"
                  style={{
                    letterSpacing: "0.1em",
                    marginLeft: "0.5em",
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  {t("nav.register")}
                </Button>
              </div>
            )}
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Nav;
