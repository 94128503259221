import { Button } from "@mui/material";
import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@mui/styles";
import { navigate } from "gatsby";

function LanguageSwitchButton(props) {
  const { originalPath, language } = useI18next();
  const theme = useTheme();
  const onClick = (event) => {
    event.preventDefault();
    navigate(`/${props.language}${to}`);
  };
  const to =
    props.originalPath === false ? "/" : props.originalPath || originalPath;
  return (
    <Button
      style={{
        backgroundColor:
          language === props.language ? theme.palette.servier.blue3 : "white",
      }}
      onClick={onClick}
    >
      {props.language}
    </Button>
  );
}

const LanguageSwitcher = (props) => {
  const { languages, language, originalPath } = useI18next();
  return (
    <div className="LanguageSwitcher">
      {languages.map((item) => (
        <LanguageSwitchButton
          key={item}
          originalPath={item === language ? originalPath : props.alternateHref}
          language={item}
        />
      ))}
    </div>
  );
};

export default LanguageSwitcher;
